import { AxiosResponse } from 'axios';
import { BaseService } from './base';
import { UserPackage } from '@/entities/user-package/user-package';
import { User } from '@/entities/user/user';
import { Testimonial } from '@/entities/testimonial/testimonial';
import { ResetRequest } from '@/entities/reset-request/reset-request';
import { user } from '@/store';
import { Company } from '@/entities/company/company';

class UserService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  public async getByid(id: number): Promise<User> {

    return await (await this.api.get(`user/${id}`)).data;
  }

  public async getAllTestimonial(id: number): Promise<Testimonial> {

    return await (await this.api.get(`api/testimonial/company/${id}`)).data;
  }


  public async updateProfile(data: User): Promise<string> {

    return await (await this.api.post(`profile/update/`, data)).data;
  }

  public async existsByEmail(email: string): Promise<boolean> {

    return await (await this.api.get(`user/checkuser/${email}/`)).data;
  }

  public async forgetPassword(email: string): Promise<string> {

    return await (await this.api.get(`/api/password/forget/${email}/`)).data;
  }

  public async checkPasswordToken(email: string): Promise<boolean> {

    return await (await this.api.get(`/api/password/checktoken/${email}/`)).data;
  }

  public async resetPassword(data: ResetRequest): Promise<string> {

    return await (await this.api.post(`/api/password/reset/`, data)).data;
  }

  public async getValue(id: number): Promise<string> {

    return await (await this.api.get(`package/value/${id}`)).data;
  }

  public async getCompany(id: number): Promise<Company> {

    return await (await this.api.get(`/api/company/get/${id}`)).data;
  }


  public async getFee(id: number): Promise<Company> {

    return await (await this.api.get(`/api/fee/company/${id}`)).data;
  }





  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service = new UserService();

export {
  service as default,
  service as UserServices,
};
